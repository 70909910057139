import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useWebform } from 'src/hooks/useWebform';
import RichText from '../components/rich_text/RichText';
import BreederAdoptionForm from 'src/components/forms/BreederAdoptionForm';

interface StorageKittenAdoptersFormProps {
  node?: IStorageKittenAndPuppyForm;
}

const StorageKittenAdoptersForm = ({ node: data }: StorageKittenAdoptersFormProps) => {
  const { getFieldDataByName } = useWebform(data?.relationships?.form);

  return (
    <Container className="py-1">
      <Row className="justify-content-md-center">
        <Col md="8">
          <RichText body={data?.formDescription?.processed || ''} />
          <RichText body={data?.formTitle?.processed || ''} />
          <BreederAdoptionForm
            getFieldDataByName={getFieldDataByName}
            langcode={data?.langcode || 'en'}
            petType="CAT"
            // The petName prop is hardcoded to 'Kitten' in this component. Because if user doesn't enter pet name, it will be 'Kitten'.
            petName="Kitten"
            webformId={data?.relationships.form.drupal_internal__id || ''}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default StorageKittenAdoptersForm;
