/* eslint-disable complexity */
import { AxiosError } from 'axios';
import { navigate } from 'gatsby';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { useRef, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formPost } from '../../utils/clientRequests';
import {
  validateEmailPattern,
  validatePostalCodePattern,
  validateRequired,
  validateRequiredEmail,
  validateRequiredFirstName,
  validateRequiredPostalCode,
  validateRequiredProvince,
} from '../../utils/formHelpers';
import Alert from '../alert/Alert';
import RichText from '../rich_text/RichText';

interface FormValues {
  firstName: string;
  email: string;
  province: string;
  postalCode: string;
  breed: string;
  otherComments: string;
  agreed: boolean;
  language: {
    keyName: Languages;
  };
}

interface NewsletterSignUpFormProps {
  getFieldDataByName: Function;
  submitCallback?: Function;
}

const BreederReferralForm = ({ getFieldDataByName, submitCallback }: NewsletterSignUpFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      email: '',
      province: '',
      postalCode: '',
      breed: '',
      otherComments: '',
      agreed: false,
    },
  });

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiError, setApiError] = useState('');
  const apiRef = useRef<HTMLDivElement>(null);

  const onSubmit: SubmitHandler<FormValues> = async formData => {
    const formattedData = {
      webform_id: 'breeder_referral_form',
      agree_box: formData.agreed ? '1' : '0',
      first_name: formData.firstName,
      email_address: formData.email,
      postal_code: formData.postalCode,
      province_ca_: formData.province,
      breed: formData.breed,
      other_comments: formData.otherComments,
    };

    setSubmitting(true);

    const breederReferralResponse = await formPost({ data: formattedData });
    if (!breederReferralResponse) {
      setApiError('Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    if (breederReferralResponse instanceof AxiosError) {
      setApiError(breederReferralResponse.response?.data?.message || 'Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    const submitAnalyticsForm = {
      event: 'breeders_submit',
      eventCategory: 'breeders form',
      eventAction: 'successful submission',
      eventLabel: '(not set)',
      eventParams: {
        form_type: 'breeders form',
        site_id: 'purina.ca',
        form_id: 'breeders',
        form_name: 'breeders',
      },
    };
    sendIt(submitAnalyticsForm);

    if (submitCallback) {
      submitCallback();
    }

    setSubmitted(true);
    reset();
    setSubmitting(false);

    // Redirect to puppy page after submission
    setTimeout(() => {
      navigate('/pro-plan/dogs/puppy-food');
    }, 5000);
  };

  if (submitting) {
    return (
      <Row className="justify-content-center">
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{`${t('Submitting')}...`}</span>
          </Spinner>
          <span className="ms-3">{`${t('Please wait')}...`}</span>
        </Col>
      </Row>
    );
  }
  if (submitted) {
    return (
      <Row>
        <Col className="text-center">
          <Alert variant="success" className="my-3">
            <RichText body={getFieldDataByName('success_message')?.text} />
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
      <Row className="gx-3 gy-3">
        {apiError && (
          <Alert variant="error" role="alert" tabIndex={0} ref={apiRef}>
            {apiError}
          </Alert>
        )}
        <Col md={6}>
          <Form.Group controlId="firstName" className="required">
            <Form.Label>{getFieldDataByName('first_name').title}</Form.Label>
            <Form.Control
              {...register('firstName', {
                required: validateRequiredFirstName(t),
              })}
              type="text"
              isInvalid={!!errors?.firstName}
              aria-invalid={!!errors?.firstName}
              maxLength={128}
              aria-required="true"
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName && errors.firstName.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="email" className="required">
            <Form.Label>{getFieldDataByName('email_address').title}</Form.Label>
            <Form.Control
              {...register('email', {
                required: validateRequiredEmail(t),
                pattern: validateEmailPattern(t),
              })}
              type="email"
              isInvalid={!!errors?.email}
              aria-invalid={!!errors?.email}
              placeholder="example@gmail.com"
              maxLength={128}
              aria-required="true"
            />
            <Form.Control.Feedback type="invalid">
              {errors.email && errors.email.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="province" className="required">
            <Form.Label>{getFieldDataByName('province_ca_')?.title}</Form.Label>
            <Form.Select
              {...register('province', {
                required: validateRequiredProvince(t),
              })}
              isInvalid={!!errors?.province}
              aria-invalid={!!errors?.province}
              aria-required="true"
            >
              <option value="">
                {getFieldDataByName('province_ca_')?.emptyOption ?? t('Select')}
              </option>
              {(getFieldDataByName('province_ca_')?.options || []).map(
                (option: { value: string; label: string }) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ),
              )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.province && errors.province.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="postalCode" className="required">
            <Form.Label>{getFieldDataByName('postal_code').title}</Form.Label>
            <Form.Control
              {...register('postalCode', {
                required: validateRequiredPostalCode(t),
                pattern: validatePostalCodePattern(t),
              })}
              isInvalid={!!errors?.postalCode}
              aria-invalid={!!errors?.postalCode}
              placeholder={getFieldDataByName('postal_code').title}
              maxLength={7}
              required
              aria-required="true"
            />
            <Form.Control.Feedback type="invalid">
              {errors.postalCode && errors.postalCode.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="breed" className="required">
            <Form.Label>{getFieldDataByName('breed')?.title}</Form.Label>
            <Form.Select
              {...register('breed', {
                required: validateRequired(getFieldDataByName('breed').title, t),
              })}
              isInvalid={!!errors?.breed}
              aria-invalid={!!errors?.breed}
              aria-required="true"
            >
              <option value="">{getFieldDataByName('breed')?.emptyOption ?? t('Select')}</option>
              {(getFieldDataByName('breed')?.options || []).map(
                (option: { value: string; label: string }) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ),
              )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.breed && errors.breed.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="otherComments">
            <Form.Label>{getFieldDataByName('other_comments').title}</Form.Label>
            <Form.Control {...register('otherComments')} as="textarea" rows={4} />
          </Form.Group>
        </Col>
        <Col md={6} className="d-grid gap-4">
          <Form.Group controlId="agreed">
            <Form.Check typography-h2 type="checkbox" label={getFieldDataByName('agree_box').title}>
              <Form.Check.Input type="checkbox" aria-required="false" {...register('agreed')} />
              <Form.Check.Label>{getFieldDataByName('agree_box').title}</Form.Check.Label>
            </Form.Check>
          </Form.Group>

          {getFieldDataByName('disclaimer').text && (
            <RichText className="small" body={getFieldDataByName('disclaimer').text} />
          )}
        </Col>

        <Col md={6}>
          <Button
            className="justify-content-center mt-3 js-track"
            type="submit"
            size="sm"
            style={{ padding: '.5rem 3rem' }}
            disabled={submitting}
          >
            {submitting ? 'Please wait...' : getFieldDataByName('actions').submitLabel}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BreederReferralForm;
