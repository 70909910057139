import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  validateEmailPattern,
  validatePostalCodePattern,
  validateRequired,
  validateRequiredEmail,
  validateRequiredFirstName,
  validateRequiredLastName,
  validateRequiredPostalCode,
} from 'src/utils/formHelpers';
import RichText from '../rich_text/RichText';
import { formPost } from 'src/utils/clientRequests';
import { AxiosError } from 'axios';

interface FormValues {
  date: string;
  firstName: string;
  lastName: string;
  petName: string | 'Kitten' | 'Puppy';
  petBirthday: string;
  petType: 'CAT' | 'DOG';
  email: string;
  postalCode: string;
  locale: {
    keyName: Languages;
  };
  agreed: boolean;
  breederId: string | null;
}

interface BreederAdoptionFormProps {
  getFieldDataByName: Function;
  langcode: Languages;
  petType: 'CAT' | 'DOG';
  petName: 'Kitten' | 'Puppy';
  webformId: string;
}

const BreederAdoptionForm = ({
  getFieldDataByName,
  langcode,
  petType,
  petName,
  webformId,
}: BreederAdoptionFormProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const breederId = queryParams.get('breeder_id');

  // The breeder_id is required. If it is missing, redirect to breeder page.
  useEffect(() => {
    if (breederId === null) {
      navigate('/find-a-dog-breeder');
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      date: new Date().toISOString().slice(0, 10),
      firstName: '',
      lastName: '',
      petName: '',
      petBirthday: Date(),
      petType,
      email: '',
      postalCode: '',
      locale: {
        keyName: 'en',
      },
      agreed: false,
      breederId: breederId,
    },
  });

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [apiError, setApiError] = useState('');
  const apiRef = useRef<HTMLDivElement>(null);

  const onSubmit: SubmitHandler<FormValues> = async formData => {
    setSubmitting(true);
    const formattedData = {
      webform_id: webformId,
      date: formData.date,
      first_name: formData.firstName,
      last_name: formData.lastName,
      pet_name: formData.petName === '' ? petName : formData.petName,
      pet_birthday: formData.petBirthday,
      pet_type: formData.petType,
      email_address: formData.email,
      postal_code: formData.postalCode,
      language: langcode || 'en',
      agree_box: formData.agreed,
      breeder_id: breederId,
    };

    const breederAdoptionResponse = await formPost({ data: formattedData });

    if (!breederAdoptionResponse) {
      setApiError('Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    if (breederAdoptionResponse instanceof AxiosError) {
      setApiError(breederAdoptionResponse.response?.data?.message || 'Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    setSubmitted(true);
    setSubmitting(false);
  };

  if (submitting) {
    return (
      <Row className="justify-content-center py-5">
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{`${t('Submitting')}...`}</span>
          </Spinner>
          <span className="ms-3">{`${t('Please wait')}...`}</span>
        </Col>
      </Row>
    );
  }
  if (submitted) {
    return (
      <Alert variant="success" className="my-3 d-flex-column justify-content-center">
        <RichText body={getFieldDataByName('success_message')?.text} />
      </Alert>
    );
  }

  return (
    <Container className="py-5">
      <Row className="justify-content-md-center gx-3 gy-3 ">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
          {apiError && (
            <Alert variant="error" role="alert" tabIndex={0} ref={apiRef}>
              {apiError}
            </Alert>
          )}
          <Row className="gx-3 gy-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="firstName" className="required">
                <Form.Label>{getFieldDataByName('first_name').title}</Form.Label>
                <Form.Control
                  {...register('firstName', {
                    required: validateRequiredFirstName(t),
                  })}
                  type="text"
                  maxLength={60}
                  aria-required="true"
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.firstName && errors.firstName.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="lastName" className="required">
                <Form.Label>{getFieldDataByName('last_name').title}</Form.Label>
                <Form.Control
                  {...register('lastName', {
                    required: validateRequiredLastName(t),
                  })}
                  type="text"
                  maxLength={60}
                  aria-required="true"
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.lastName && errors.lastName.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3 gy-3 mt-1">
            <Col xs={12} md={6}>
              <Form.Group controlId="petName">
                <Form.Label>{getFieldDataByName('pet_name').title}</Form.Label>
                <Form.Control {...register('petName')} type="text" maxLength={60} />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="petBirthday" className="required">
                <Form.Label>{getFieldDataByName('pet_birthday').title}</Form.Label>
                <Form.Control
                  {...register('petBirthday', {
                    required: validateRequired('Pet Birthday', t),
                  })}
                  type="date"
                  aria-required="true"
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.petBirthday && errors.petBirthday.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3 gy-3 mt-1">
            <Col xs={12} md={6}>
              <Form.Group controlId="email" className="required">
                <Form.Label>{getFieldDataByName('email_address').title}</Form.Label>
                <Form.Control
                  {...register('email', {
                    required: validateRequiredEmail(t),
                    pattern: validateEmailPattern(t),
                  })}
                  type="email"
                  placeholder="example@gmail.com"
                  maxLength={128}
                  aria-required="true"
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.email && errors.email.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="postalCode" className="required">
                <Form.Label>{getFieldDataByName('postal_code').title}</Form.Label>
                <Form.Control
                  {...register('postalCode', {
                    required: validateRequiredPostalCode(t),
                    pattern: validatePostalCodePattern(t),
                  })}
                  type="postalCode"
                  maxLength={128}
                  aria-required="true"
                  isInvalid={!!errors?.postalCode}
                  aria-invalid={!!errors?.postalCode}
                  required
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.postalCode && errors.postalCode.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3 gy-3 mt-1">
            <Col xs={12}>
              <Form.Group controlId="agreed">
                <Form.Check typography-h2 type="checkbox">
                  <Form.Check.Input {...register('agreed')} type="checkbox" />
                  <Form.Check.Label aria-hidden="true">
                    {getFieldDataByName('disclaimer').text && (
                      <RichText body={getFieldDataByName('disclaimer').text} />
                    )}
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3 gy-3 mt-1">
            <Col xs={12}>
              <Button
                className="justify-content-center mt-3"
                type="submit"
                size="sm"
                style={{ padding: '.5rem 3rem' }}
                disabled={submitting}
              >
                {submitting ? 'Please wait...' : getFieldDataByName('actions').submitLabel}
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </Container>
  );
};

export default BreederAdoptionForm;
